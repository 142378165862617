import React from 'react';
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FaWhatsapp, FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"
import { IoMdMail } from "react-icons/io"

import Layout from "../components/layout"
import Facebook from '../components/social/facebook';
import Whatsapp from '../components/social/whatsapp';
import Twitter from '../components/social/twitter';
import Linkedin from '../components/social/linkedin';
import Email from '../components/social/email';
import SEO from '../components/seo';

const BlogTemplate = ({ data }) => {

    const siteURL = encodeURIComponent(data.site.siteMetadata.url)
    const URLpath = encodeURIComponent(data.blogPost.slug)
    const linkText = encodeURIComponent(data.blogPost.title)

    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {

                const url = node.data.target.fields.file["en-US"].url
                const alt = node.data.target.fields.title["en-US"]

                return <img style={{ width: "100%" }} alt={alt} src={url}/>
            }
        }
    }

    return (  
        <Layout>
            <SEO 
            pageType="article"
            linkTitle={`${data.blogPost.title} - Gary Hynds`}
            pageDescription={data.blogPost.excerpt.excerpt}
            pageURL={`${data.site.siteMetadata.url}${data.blogPost.slug}`}
            pageTitle={`${data.blogPost.title}  |  Gary Hynds`}
            linkImage={`https:${data.blogPost.featuredImage.file.url}`}
            />
            <TopBanner>
                <TopWrapper>
                    <BlogDate>{data.blogPost.date}</BlogDate>
                    <h1 style={{ textAlign: "center", lineHeight: "2.2"}}><BlogTitle>{data.blogPost.title}</BlogTitle></h1>
                    <ShareLabel>SHARE THIS</ShareLabel>
                    <TopSocialButtons>
                        <SocialIcon
                        href={`https://facebook.com/sharer/sharer.php?u=${siteURL}${URLpath}`} 
                        target="_blank" 
                        rel="noopener" 
                        aria-label="Share on Facebook"
                        ><FaFacebookF className="facebook-top"/></SocialIcon>
                        <SocialIcon
                        href={`whatsapp://send?text=${linkText}%20${siteURL}${URLpath}`}
                        target="_blank" 
                        rel="noopener" 
                        aria-label="Share on WhatsApp"
                        ><FaWhatsapp className="whatsapp-top"/></SocialIcon>
                        <SocialIcon
                        href={`https://twitter.com/share?url=${siteURL}${URLpath}&text=${linkText}&via=GaryHynds`} 
                        target="_blank" 
                        rel="noopener" 
                        aria-label="Share on Twitter"
                        ><FaTwitter className="twitter-top"/></SocialIcon>
                        <SocialIcon
                        href={`https://www.linkedin.com/shareArticle?url=${siteURL}${URLpath}&title=${linkText}`}
                        target="_blank" 
                        rel="noopener" 
                        aria-label="Share on LinkedIn"
                        ><FaLinkedinIn className="linkedin-top"/></SocialIcon>
                        <SocialIcon
                        href={`mailto:?subject=${linkText}&body=garyhynds.com%2F${URLpath}`} 
                        target="_self" 
                        rel="noopener" 
                        aria-label="Share by E-Mail"
                        ><IoMdMail className="email-top"/></SocialIcon>

                    </TopSocialButtons>
                </TopWrapper>    
            </TopBanner>
            <LowerSection>
                <BlogBody>
                    <FeaturedImage fluid={data.blogPost.featuredImage.fluid}/>
                    <Blog>{documentToReactComponents(data.blogPost.blogBody.json, options)}</Blog>

                </BlogBody>
                <BottomSocialButtons>
                    <Facebook label="Share on Facebook" siteURL={siteURL} URLpath={URLpath} linkText={linkText}/>
                    <Whatsapp label="Share on WhatsApp" siteURL={siteURL} URLpath={URLpath} linkText={linkText}/>
                    <Twitter label="Share on Twitter" siteURL={siteURL} URLpath={URLpath} linkText={linkText}/>
                    <Linkedin label="Share on Linkedin" siteURL={siteURL} URLpath={URLpath} linkText={linkText}/>
                    <Email label="Share via Email" siteURL={siteURL} URLpath={URLpath} linkText={linkText}/>
                </BottomSocialButtons>

            </LowerSection>
        </Layout>
    );
}
 
export default BlogTemplate;

export const pageQuery = graphql`
    query ($slug: String!) {
            blogPost: contentfulBlog(slug: {eq: $slug}) {
            slug
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
                fluid(maxWidth: 2000) {
                    ...GatsbyContentfulFluid
                }
                file {
                    url
                }
            }
            blogBody {
                json
            }
            excerpt {
                excerpt
            }
        }
        site {
            siteMetadata {
                url
            }
        }
    }   
  
`

const TopBanner = styled.div`
  background-color: #0a3b7c;
  position: relative;
  padding-top: 60px;
  padding-bottom: 120px;
  margin-top: 68px;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  @media (max-width: 450px) {
    padding-bottom: 100px;
    padding-top: 40px;
  }
`

const TopWrapper = styled.div`
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    max-width: 850px;
    padding-left: 20px;
    padding-right: 20px;
`

const BlogDate = styled.p`
    display: block;
    font-family: 'Montserrat', sans-serif !important;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 40px;
    margin-top: 50px;
`

const ShareLabel = styled.p`
    display: block;
    font-family: 'Montserrat', sans-serif !important;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 20px;
`

const TopSocialButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const SocialIcon = styled.a`
  color: #FFFFFF;
  font-size: 40px;
  margin-left: 10px;
  margin-right: 10px;
  @media (max-width: 450px) {
    font-size: 9vw;
  }

  .facebook-top:hover {
    color: #1778f2 !important;
    transition: all 300ms linear 0s !important;
  }

  .whatsapp-top:hover {
    color: #4ac959;
    transition: all 300ms linear 0s ;
  }
  
  .twitter-top:hover {
    color: #1da1f2;
    transition: all 300ms linear 0s ;
  }

  .linkedin-top:hover {
    color: #0e76a8;
    transition: all 300ms linear 0s ;
  }

  .email-top:hover {
    color: #f2171b;
    transition: all 300ms linear 0s ;
  }
`

const BlogTitle = styled.span`
  color: #FFFFFF;
  text-align: center;
  font-size: 38px;
  font-weight: 900;
  background-color: #00AEEF;
  padding: 10px;
  display: inline;
  font-family: 'Montserrat', sans-serif !important;
  box-shadow: 10px 0 0 #00aeef, -10px 0 0 #00aeef;


  @media (max-width: 450px) {
    font-size: 7vw;
  }
  
`

const LowerSection = styled.div`
  background-color: #FFFFFF;
  padding-top: 80px;
  padding-bottom: 60px;
  position: relative
`

const BlogBody = styled.div`
  max-width: 1200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 450px) {
      padding-left: 5px;
      padding-right: 5px;
    }
`

const FeaturedImage = styled(Img)`
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -150px;
  margin-bottom: 60px;
  @media (max-width: 450px) {
        margin-bottom: 20px;
    }
`

const Blog = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: black;
  line-height: 24px !important;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 4.3vw;
}
`

const BottomSocialButtons = styled.div`
    max-width: 800px !important;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-template-rows: auto;
    align-items: center; !important;
    justify-content: center;
    justify-items: stretch;
    padding-top: 40px;

    font-size: 16px;

    @media (max-width: 768px) {
        max-width: 480px !important;
        font-size: 1.8vw;
        grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 450px) {
        width: 250px;
        font-size 3.8vw;
        grid-template-columns: 1fr !important;
    }
    
    
`